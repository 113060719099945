import React from 'react'
import { NextSeo } from 'next-seo'
import Footer from '../components/Footer'
import styles from './error.module.sass'
import HeaderBar from '../components/HeaderBar'
import { serverSideTranslations } from 'next-i18next/serverSideTranslations'
import getLanguage from '../utils/i18n'

export default function Custom404() {
  return (
    <>
        <div className={styles.container_wrapper}>
          <div className={styles.header}>
            <NextSeo
              title='Error 404'
              nofollow={true}
              openGraph= {{
                title: 'Error 404'
              }}
            />
            <HeaderBar />
          </div>
          <h1 className={`${styles.title} text-center`}>Error 404 Page not found</h1>
          <div className={styles.description}>
            The page you requested coulnd’t be found
          </div>
          <div className={styles.logo_wrapper}>
            <img src={'/images/404.svg'} alt='404 logo' className={styles.logo} />
          </div>
        </div>
        <Footer />
      </>
  )
}

export const getStaticProps = async ctx => ({
  props: {
    ...(await serverSideTranslations(getLanguage(ctx), ['common', 'header', 'footer', 'about'])),
  }
});
